// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.scss';
// import App from './App';
// import { BrowserRouter, useLocation } from 'react-router-dom';
// import { Provider } from 'react-redux';
// import store from './config/Store';
// import './i18n/config';

//   const location = useLocation();
//   const searchParams = new URLSearchParams(location.search);
//   const DOCUMENT_id = searchParams.get("id"); // Here we extract the id from the query params


// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <Provider store={store}>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </Provider>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(//  console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// //reportWebVitals();



import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './config/Store';
import './i18n/config';

const AppWrapper = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ExternalTOKEN :any= searchParams.get("token"); 
  if(ExternalTOKEN){
    localStorage.setItem("token",ExternalTOKEN);
    localStorage.setItem("ExternalTOKEN",ExternalTOKEN);
    console.log("ExternalTOKEN",ExternalTOKEN);
  }

  return <App  />;
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <AppWrapper />
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import { SET_IS_REFRESH, SET_SIGNUP_TYPE, SET_SIGNUP_DATA, USER_INFO, UPDATE_CART, ADD_CART_DATA, ADD_ADDRESS_DATA, ADD_PRICE_DATA, UPDATE_REDUX, ADD_CATEGORY_DATA, FORGET_PASSWORD_DATA, DOCUMENT_NAME, SEARCH_KEYWORD } from "../action/CommonAction";


export interface isRefreshState {
  isRefresh: any;
}

interface cartInterface {
  price: any;
  cart: any[];
}

interface forgetPass {
  email: string;
  otp: string
}

export interface reduxState {
  signUpType: string;
  isRefresh: boolean;
  update_cart: any;
  cartData: cartInterface;
  address: any;
  forget_pass: forgetPass;
  category: any;
  documentData: any;
  searchKeyword:any
  // signupData: signupFormInterface;
  // user_info: any;
}

const initialState: reduxState = {
  signUpType: "",
  isRefresh: false,
  update_cart:"",
  cartData: {} as cartInterface,
  address: {},
  forget_pass: {} as forgetPass,
  category: {},
  documentData: {},
  searchKeyword:{}
  // signupData: {} as signupFormInterface,
}


interface Action {
  type: string;
  payload?: any;
}

export const commonReducerData = (state: reduxState = initialState, action: Action) => {
  switch (action.type) {
    case UPDATE_REDUX: return { ...action.payload };
    case SET_SIGNUP_TYPE: return { ...initialState, ...{ signUpType: action.payload } };
    case SET_IS_REFRESH: return { ...initialState, ...{ isRefresh: action.payload } };
    case SET_SIGNUP_DATA: return { ...initialState, ...{ signupData: action.payload } };
    case UPDATE_CART: return { ...initialState, ...{ update_cart: action.payload } };
    case ADD_CART_DATA: return { ...initialState, ...{ cartData: action.payload } };
    case ADD_ADDRESS_DATA: return { ...initialState, ...{ address: action.payload } };
    case FORGET_PASSWORD_DATA: return { ...initialState, ...{ forget_pass: action.payload } };
    case ADD_CATEGORY_DATA: return { ...initialState, ...{ category: action.payload } };
    case DOCUMENT_NAME: return { ...initialState, ...{ documentData: action.payload } };
    case SEARCH_KEYWORD: return { ...initialState, ...{ searchKeyword: action.payload } };

    default: return state;
  }
};

export const updateuserInfo = (state: any = { user_info: {} }, action: Action) => {
  switch (action.type) {
    case USER_INFO: return { user_info: action.payload };
    default: return state;
  }
};





